.section {
    padding: 20px;
    background-color: #f5f5f5;
    margin-bottom: 40px;
  }
  
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .advisor-info {
    display: flex;
    align-items: center;
  }
  
  .advisor-image {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-right: 20px;
  }
  
  .creator-info {
    text-align: right;
  }


  .ant-radio-button-wrapper-checked {
    background-color: #181cff !important;
    color: white !important;
  }