@import '../../.././../styles/vars.css';

.card_purple{
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 10px;
    background-color: "#e6e6ff" !important
}


.card_folio .ant-card-meta-title {
    color: #341eff !important;
    font-weight: 600;
    font-size: 24px;
  }
  .card_folio .ant-card-meta-detail .ant-card-meta-title {
    height: 30px;
  }
  .card_folio .ant-card-meta-description {
    color: #341eff !important;
  }