@import '../../.././../styles/vars.css';

.button_header{
    color: var(--primaryColor) !important;
    border: 1px solid rgb(209, 209, 209) !important;
}

.button_header_active{
    background-color: var(--primaryColor) !important;
    color: white;
    border: 1px solid rgb(209, 209, 209) !important;
}

.button_header_active img{
    margin-right: 10px;
    margin-top: -2px;
    width: 15px;
    filter: brightness(0) invert(1) !important;
}

.button_header img {
    margin-right: 10px;
    margin-top: -2px;
    width: 15px;

}


.button_header:hover, .button_header_active:hover{
    color: white !important;
    background-color: var(--primaryColor);
}


.button_header:hover img, .button_header_active:hover img{
    filter: brightness(0) invert(1) !important;
}

