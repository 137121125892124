@import "vars.css";

@font-face {font-family: "Sharp Grotesk"; src: url("//db.onlinewebfonts.com/t/f4a88c23d1f27870fb1e458ace76a752.eot"); src: url("//db.onlinewebfonts.com/t/f4a88c23d1f27870fb1e458ace76a752.eot?#iefix") format("embedded-opentype"), url("//db.onlinewebfonts.com/t/f4a88c23d1f27870fb1e458ace76a752.woff2") format("woff2"), url("//db.onlinewebfonts.com/t/f4a88c23d1f27870fb1e458ace76a752.woff") format("woff"), url("//db.onlinewebfonts.com/t/f4a88c23d1f27870fb1e458ace76a752.ttf") format("truetype"), url("//db.onlinewebfonts.com/t/f4a88c23d1f27870fb1e458ace76a752.svg#Sharp Grotesk Book 20") format("svg"); }

.d-flex{
    display: flex;
}
.m-0{
    margin: 0% !important;
}
.mb-0{
    margin-bottom: 0% !important;
}
.mt-0{
    margin-top: 0% !important;
}
.ml-0{
    margin-left: 0;
}
.mr-0{
    margin-right: 0% !important;
}
.m-auto{
    margin: auto !important;
}

.ant-layout-header {
    line-height: 0%;
}

.site-header__input{
    border-radius: 10px;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 15px;
    display: flex !important;
}
.br-5{
    border-radius: 5px;;
}
.br-10{
    border-radius: 10px;;
}
.btns_services{
    color: var(--secondaryColor);
}

.btn-gray{
    background-color: var(--grayColor);
    color: white;
}

.btn-low-blue{
    background-color: #6871F0;
    color: white;
}

.btn-blue{
    background-color: var(--secondaryColor);
    color: white;
}

.btn-string-blue{
    background-color: var(--primaryColor);
    color: white;
}

.btn-string-blue-low, .btn-string-blue-low:hover,.btn-string-blue-low:focus{
    background-color: var(--secondaryColor);
    color: white;
}

.btn-report-action{
    min-width: 160px;
    height: 50px;
}
.btn-blue-hove:hover{
    background: var(--secondaryColor);
    color: white;   
}

.ant-layout-header{
    background: transparent !important;
}
.custom-divider{
    border-bottom: solid 1px var(--secondaryColor);
    margin: 5px 0px 15px;
}
.item-form-user{
    border-radius: 10px;
    color: var(--secondaryColor);
    padding: 10px 15px;
}

.customer-option{
    color: var(--secondaryColor);
    cursor: pointer;
    text-decoration: underline;
}
.selectFormCredit > .ant-select-selector{
    /* border-radius: 10px !important; */
    border-radius: 10px !important;
    color: var(--secondaryColor) !important;
    padding: 10px 15px;
}

.selectFormCredit .ant-select-arrow{
    color: var(--secondaryColor);
}
.b-r-10{
    border-radius: 10%;
}
.divider-blue{
    border-bottom: 1px solid var(--secondaryColor);
    margin-top: 0;
    margin-bottom: 10px;
}
.selectFormCredit .ant-select-selection-placeholder{
    color: var(--secondaryColor);
}

.selectFormCredit .ant-select-selection-search-input{
    height: 100% !important;
}

.title-modal-filter{
    margin-bottom: 5px !important;
}
/* .texts_blue {
    color: val(--secondaryColor);;
} */
.radioCheck label.ant-radio-button-wrapper{
    height: 100%;
    padding: 9px 38px 9px 20px;
    background-color: var(--secondaryColor);
    color: white;
    border-radius: 10px;
    margin-right: 10px;
 
}
.radioCheck label.ant-radio-button-wrapper span.anticon-check{
       color:transparent;
}
.radioCheck label.ant-radio-button-wrapper-checked{
    background-color: var(--primaryColor) !important;
    color: white;
}
.radioCheck label.ant-radio-button-wrapper-checked span.anticon-check{
    color: white;
}
.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before{
    background-color: transparent;
}
.radioItemForm{
    height: 100%;
}
.radioItemForm .ant-form-item-control{
    height: 100%;
}
.radioItemForm .ant-form-item-control .ant-form-item-control-input{
    height: 100%;
}
.font-white{
    color: white !important;
}
.font-primary{
    color: var(--primaryColor) !important
}
.border-bottom-primary{
    border-bottom: solid 2px var(--primaryColor);
}

.btn-login:disabled{
    background-color: var(--primaryColor);
    color: white;
    opacity: 0.5;
    
}

.btn-login, .btn-login:hover{
    background-color: var(--primaryColor);
    color: white;
}

.card_details_service{
    border-radius: 10px;
    margin-top: 20px;
    padding-top: 20px;
}

.divider-primary{
    border: solid .5px gray;
}

.px-30{
    padding-left: 30px;
    padding-right: 30px;
}

.divPhotoUSer{
    padding: 20px;
    border: solid 1px black;
}

.previewPhoto{
    width: 100% !important;
}

.formUser .ant-form-item-label label{
    font-weight: normal;
}
.boardFullScreen{
    min-height:100vh;
    width: 100vw;
    position: absolute;
    top:0;
    left:0
}

.react-trello-board{
    background-color: var(--primaryColor) !important;
}
.custom-card-slectroplan .ant-card-head{
    padding: 10px;
    min-height: auto;
}

.custom-card-slectroplan .ant-card-body{
    padding: 10px !important;
}

.custom-card-slectroplan .ant-card-head .ant-card-head-title{
    padding: 0px;
}

