/* Inputs */

.kinput{
    border-radius: 6px !important;
    padding: 6.5px 11px;
    font-size: 16px;
    color: var(--primaryColor);
}

.kinput-search{
    border-radius: 6px !important;
    font-size: 16px;
    color: var(--primaryColor);
}