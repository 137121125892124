.tool-bar {
    background-color: blue;
    color: rgba(255, 255, 255, 0);
    display: list-item;
    gap: 1rem;
    align-items: right;
    padding: 1rem;
    font-size: 20px;
    padding-bottom: 10px;
    position: fixed;
    height: 100vh;
    right: 0;
    padding-right: 1px;
    padding-left: 2px;
    z-index: 100;

}

.sidebar {
    position: fixed;
    background-color: blue;
    color: #fff;
    top: 0;
    right: 0;
    transform: translateX(100%);
    /*A que lado se ocultara*/
    transition: all 0.4s;
    font-size: 16px;
    width: 280px;
    height: 100vh;
    font-weight: bold;
    margin-top: 199px;
    z-index: 100;
}

.sidebar li {
    list-style: none;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    gap: 1rem;
    font-weight: 300;
    font-size: 16px;
    border-radius: 20px;
    margin-bottom: 2px;
}

.sidebar li:hover {
    background-color: #fff;
    color: blue;
    font-size: 16px;
    margin-right: 10px;
    margin-left: 20px;
}

.tool-bar p:hover {
    background-color: #fff;
    display: inline-block;
    border-radius: 2px;
}

.sidebar--open {
    transform: translateX(0%);
}

.backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-color: rgb(0, 0, 0, 0.5);
    display: none;
}

.backdrop--open {
    display: block;
}

.title {
    margin-left: 10px;
    margin-right: 10px;
    margin-bottom: 15px;
}


.burger {
    margin-left: 15px;
    margin-bottom: 20px;
}

.flecha {
    margin-right: 200px;
    margin-left: 20px;
    margin-top: 20px;
}

.posicion {
    margin-top: 10px;
}

.mensaje {
    margin-bottom: 17px;
    margin-left: 5px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    padding-top: 3px;
    border-radius: 1px;
}

.clip {
    margin-bottom: 17px;
    padding-left: 4px;
    padding-right: 4px;
    padding-bottom: 4px;
    padding-top: 3px;
    border-radius: 1px;
    margin-left: 3px;

}

.cocheLlave {
    margin-bottom: 17px;
    margin-left: 5px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    padding-top: 3px;
    border-radius: 1px;
}

.cocheRoto {
    margin-left: 2px;
    margin-bottom: 17px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    padding-top: 3px;
    border-radius: 1px;
}

.basurero {
    margin-left: 5px;
    padding-left: 8px;
    padding-right: 8px;
    padding-bottom: 8px;
    padding-top: 3px;
    border-radius: 1px;
}

.rotarImagen {
    transform: rotate(-180deg);
}