
:root {
    --primaryColor:#341EFF;
    --secondaryColor:#090731;
    --thirdColor:#000000;
    --grayColorDefault:#909090;
    --colorInputGray:#f0f0f8;
    --grayColor: #707070;
    --colorSuccess:#7DD628;
    --colorError:#E0394D
}
