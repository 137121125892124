@import "vars.css";

.ant-btn{
  border-radius:5px ;
}

.button-secondary{
  border-color: var(--secondaryColor) !important;
  background: var(--secondaryColor);
  color:white;
}

.button-primary{
  border-color: var(--primaryColor) !important;
  background: var(--primaryColor);
  color: white;
}

.strong_blue{
  border-radius: 10px;
  background-color: #08092D;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 42px;
  padding-left: 30px;
  padding-right: 30px;
}

.soft_blue{
  border-radius: 10px;
  background-color: #341EFF;
  color: #fff;
  padding-top: 10px;
  padding-bottom: 42px;
  padding-left: 30px;
  padding-right: 30px;
}