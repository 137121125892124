@import '../../.././../styles/vars.css';



/*Boton  success */
.button_success{
    background-color: var(--colorSuccess) !important;
    color:white !important;
}

.button_success img{
    margin-right: 10px;
    margin-top: -2px;
    width: 15px;
    filter: brightness(0) invert(1) !important;
}


.button_success:hover, .button_success:focus{
    color: white !important;
    border-color:var(--colorSuccess);
    background-color: var(--colorSuccess);
}


.button_success:hover img{
    filter: brightness(0) invert(1) !important;
}

.button_success[disabled]{
    opacity: 0.7 !important;
    color: white;
}


/*Boton  error */
.button_error{
    background-color: var(--colorError) !important;
    color:white !important;
}

.button_error img{
    margin-right: 10px;
    margin-top: -2px;
    width: 15px;
    filter: brightness(0) invert(1) !important;
}


.button_error:hover, .button_error:focus{
    color: white !important;
    border-color:var(--colorError);
    background-color: var(--colorError);
}


.button_error:hover img{
    filter: brightness(0) invert(1) !important;
}

.button_error[disabled]{
    opacity: 0.7 !important;
    color: white;
}


/*Boton  primaro */
.button_primary{
    background-color: var(--primaryColor) !important;
    color:white !important;
}

.button_primary img{
    margin-right: 10px;
    margin-top: -2px;
    width: 15px;
    filter: brightness(0) invert(1) !important;
}


.button_primary:hover, .button_primary:focus{
    color: white !important;
    border-color:var(--primaryColor);
    background-color: var(--primaryColor);
}


.button_primary:hover img{
    filter: brightness(0) invert(1) !important;
}

.button_primary[disabled]{
    opacity: 0.7 !important;
    color: white;
}



/*Boton  outline */
.button_outline{
    border: 1px solid var(--secondaryColor) !important;
    color:black
}

.button_outline[disabled]{
    opacity: 0.7 !important;
    color: white;
}

.button_outline img{
    margin-right: 10px;
    margin-top: -2px;
    width: 15px;
}


.button_outline:hover, .button_outline:focus{
    color: white !important;
    background-color: var(--secondaryColor) !important;;
}


.button_outline:hover img{
    filter: brightness(0) invert(1) !important;
}


/*Boton  secundario */
.button_secondary{
    background-color: var(--secondaryColor) !important;
    color:white !important;
}

.button_secondary img{
    margin-right: 10px;
    margin-top: -2px;
    width: 15px;
    filter: brightness(0) invert(1) !important;
}

.button_secondary span svg[data-icon]{
    fill: white;
}

.button_secondary:hover, .button_secondary:focus{
    color: white !important;
    border-color:var(--secondaryColor);
    background-color: var(--secondaryColor);
}


.button_secondary:hover img{
    filter: brightness(0) invert(1) !important;
}

.button_secondary[disabled]{
    opacity: 0.7 !important;
    color: white;
}



/*Boton  third */
.button_third{
    background-color: var(--thirdColor) !important;
    color:white !important;
}

.button_third img{
    margin-right: 10px;
    margin-top: -2px;
    width: 15px;
    filter: brightness(0) invert(1) !important;
}


.button_third:hover, .button_third:focus{
    color: white !important;
    border-color:var(--thirdColor);
    background-color: var(--thirdColor);
}


.button_third:hover img{
    filter: brightness(0) invert(1) !important;
}

.button_third[disabled]{
    opacity: 0.7 !important;
    color: white;
}


/*Boton  default */
.button_default{
    background-color: var(--grayColorDefault) !important;
    color:white !important;
}

.button_default img{
    margin-right: 10px;
    margin-top: -2px;
    width: 15px;
    filter: brightness(0) invert(1) !important;
}


.button_default:hover, .button_default:focus{
    color: white !important;
    border-color:var(--grayColorDefault);
    background-color: var(--grayColorDefault);
}


.button_default:hover img{
    filter: brightness(0) invert(1) !important;
}

.button_default[disabled]{
    opacity: 0.7 !important;
    color: white;
}

