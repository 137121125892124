@import "vars.css";
@import "buttons.css";
@import "Inputs.css";
/*Personalizacion de botones*/

.bg-primary {
    background: var(--primaryColor) !important;
}

.bg-secondary {
    background: var(--secondaryColor) !important;
}

.text-primary {
    color: var(--primaryColor) !important;
}

.text-secondary {
    color: var(--secondaryColor) !important;
}

.textarea-gray {
    background-color: var(--colorInputGray) !important;
    color: #4d4d4d;
    border-radius: 8px
}


.ant-form-vertical .ant-form-item-label, .ant-col-24.ant-form-item-label, .ant-col-xl-24.ant-form-item-label {
    /* padding: 0 0 8px; */
    line-height: 1.5715;
    /* white-space: initial; */
    text-align: left;
    padding: 0;

}

.ant-form-item-label>label {
    font-size: 14px !important;
    font-weight: bolder;
}

/*layouts*/
/**/

.content-secondary {
    background: var(--secondaryColor);
    margin: 20px;
    min-height: 600px;
}

.header-secondary {
    background: var(--primaryColor);
}

.site-layout-content {
    min-height: 280px;
    padding: 24px;
    background: var(--primaryColor);
}

#components-layout-demo-top .logo {
    float: left;
    width: 120px;
    height: 31px;
    margin: 16px 24px 16px 0;
    background: var(--primaryColor);
}

.ant-row-rtl #components-layout-demo-top .logo {
    float: right;
    margin: 16px 0 16px 24px;
}

.layoutbackground {
    background: var(--secondaryColor);
}

.layoutbackgroundbussiness {
    background: #FFFFFF;
}

.bussiness-layout-content {
    min-height: 280px;
    background: #FFFFFF;
}

.business-content-secondary {
    background-color: #2D2CF5;
    min-height: 745px;
}

.ant-layout-header {
    background-color: #F3F2FF !important;
    margin: 0 16px;
}

.site-header__space {
    width: 100%;
    margin-top: 10px;
    vertical-align: middle !important;
}

.site-header__logo {
    float: left;
    margin-top: 5px;
    font-size: 36px;
}

.site-header__search {
    float: left;
    margin-left: 20px;
    justify-content: center !important;
}

.site-header__input {
    border-radius: 10px;
    margin-top: 8px;
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 10px;
    display: flex !important;
}

.site-header__icon {
    font-size: 20px;
    color: #2D2CF5;
}

.site-header__proceedings {
    float: left;
}

.site-header__button {
    border-radius: 10px;
    background-color: #08092D;
    color: #fff;
    padding-top: 10px;
    padding-bottom: 42px;
    padding-left: 30px;
    padding-right: 30px;
}

.site-header__widget {
    right: 0px;
    position: absolute;
    justify-content: end;
    align-items: flex-end;
    float: right !important;
    margin-left: auto !important;
    display: flex;
    margin-right: 60px;
}

.site-header__text {
    color: #2D2CF5;
    padding: 0 !important;
    margin: 0 !important;
}

.ant-card-body {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
}

.ant-card-meta-detail>div:not(:last-child) {
    margin-bottom: 0px !important;
}

.site-header-extra {
    padding-left: 20px;
    font-size: 10px;
}

.site-header-card {
    border-radius: 10px;
}

.site-header-badge {
    bottom: 0px !important;
    margin-top: 80px;
    margin-right: 30px;
}

.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
    /* border-radius: 10px; */
    background-color: #341EFF;
    color: #fff;
}

.ant-menu-item site-side-item {
    border-radius: 10px;
}

.site-side-item {
    padding-bottom: 10px;
    padding-left: 30px;
    margin: 10px;
    margin-left: 20px;
}



.anticon .anticon-calendar{
    color: #2D2CF5;
}



.item-expediente-lg .ant-input-lg, .ant-picker-large {
    height: 40px !important;
    border-radius: 10px !important;
}

.item-expediente-lg .ant-picker-input input {
    text-align: center;
    color: var(--secondaryColor)
}

.rotate_45{
    -webkit-transform: rotate(-90deg);
 -moz-transform: rotate(-90deg);
 -ms-transform: rotate(-90deg);
 transform: rotate(-90deg);
}

.messageRight .rce-mbox-title{
    display: flex;
    flex-flow: inherit;
    justify-content: right;
    margin-bottom: 4px;
}
.messageRight .rce-mbox-title .rce-avatar-container{
    order: 2;
    width: auto !important;
    height: auto !important;
    border-radius: 50px;
    margin-right: 0px !important;
    margin-left: 10px;
}
.messageRight .rce-mbox-title span{
    order: 1;
}

.messageRight .rce-mbox-title .rce-avatar-container img{
    width: 40px !important;
    height: 40px !important;
}

.rce-mbox.rce-mbox-right{
    margin-right: 0px !important;
}

.rce-mbox-right{
    box-shadow: none !important;
}

.rce-mbox-file{
    padding-bottom: 0px !important;
}

.rce-mbox-file button{
    background-color: var(--secondaryColor) !important;
}

.rce-mbox-text::after{
    content: "" !important;
}

.rce-mbox-text{
    background-color: var(--secondaryColor) !important;
    padding: 10px !important;
    border-radius: 20px;
}
.rce-mbox-file button{
    border-radius: 20px !important;
}
.rce-mbox-file button   *, .rce-mbox-text{
    color: #fff !important;
}

.rce-mbox-time{
    display: contents;
}
.rce-mbox-time::before{
    text-align: right;
    float: right;
}
.cardChat{
    padding: 0px;
}

.cardChat .ant-card-head{
    padding: 0px 24px
}

.cardChat .ant-card-body{
    padding: 0px !important;
}

.cardChat .ant-card-body .chatMessagesArea{
    padding: 0px 24px !important;
}


/*Modal*/

.ant-modal-body {
    padding: 20px 30px;
}
.ant-modal-content {
    border-radius: 20px;
}


/*Calendar*/
.item-expediente-lg .ant-select-arrow, .ant-picker-suffix {
    border-left: solid 1px var(--primaryColor);
    padding: 5px 16px;
    right: 2px;
    height: auto;
    width: auto;
}

.ant-picker-input > input{
    color: var(--primaryColor);
}

[data-icon=calendar],[data-icon=clock-circle] {
    fill: var(--primaryColor)
}


.ant-descriptions-item-label{
    font-weight: bolder;
    color: var(--secondaryColor);
}


.ant-descriptions-item-content{
    color: var(--primaryColor);
}