.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.nav-bar{
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  box-sizing: border-box;
}

/* Estilos para el logo en el sidebar */
.logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3); /* Ajusta esto para usar una imagen o color específico */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px; /* Ajusta el tamaño de la fuente si es necesario */
  color: white; /* Color del texto del logo */
}

/* Estilos para el layout principal */
.site-layout {
  transition: all 0.2s;
}

/* Estilos para el fondo del header */
.site-layout-background {
  background-color: #001529; /* Color de fondo del header */
  align-items: center;
  justify-content: space-between;
}

/* Estilos adicionales para mejorar la apariencia general */
.ant-layout-header {
  height: 64px; /* Altura del header */
}

.ant-menu-dark {
  background: #001529; /* Color de fondo del menú lateral */
}

.ant-menu-item {
  margin: 0; /* Elimina los márgenes predeterminados */
}

/* Estilos para los botones y espacios */
button, .ant-space {
  align-items: center; /* Alinea verticalmente los ítems dentro de los botones y espacios */
}

/* Estilos para el contenido principal */
.site-layout .site-layout-background {
  padding: 24px; /* Padding para el contenido principal */
  background: #fff !important; /* Fondo blanco para el área de contenido */
  min-height: 28px; /* Altura mínima para el contenido */
  padding-left: 0;
  padding-right: 0;
  margin-left: 0;
  margin-right: 0;
}


@media (max-width: 768px) {
  .title-layout {
    font-size: 1.5rem; /* Cambia el tamaño del título a tu gusto */
  }
}

