.container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #2815bb;
    padding: 20px;
  }
  
  .loginBox {
    display: flex;
    background-color: white;
    border-radius: 10px;
    overflow: hidden;
    width: 100%;
    max-width: 1000px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  }
  
  .formSection {
    flex: 1;
    padding: 40px;
  }
  
  .logoKikert {
    z-index: 1000;
    position: absolute;
    top: 20px;
    right: 20px;
  }
  
  .title {
    color: #6C5CE7 !important;
    margin-bottom: 2rem !important;
  }
  
  .loginButton {
    background-color: #1E1E2D !important;
    border-color: #1E1E2D !important;
  }
  
  .termsCheckbox {
    color: #6C5CE7;
  }
  
  .forgotPassword {
    text-align: center;
    margin-top: 1rem;
  }
  
  .forgotPassword a {
    color: #6C5CE7;
  }
  
  @media (max-width: 768px) {
    .loginBox {
      flex-direction: column;
    }
  
    .imageSection {
      display: none;
    }
  }